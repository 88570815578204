import { SyncOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { UserContext } from '../../../../contexts/UserContext'

export const SocialConnect = ({ platform, connectionStatus, reconnect }) => {
  const {
    token: userToken,
    setPhylloError,
    fetchCurrentUser,
    updateSocialProfile,
    deleteSocialProfile,
  } = useContext(UserContext)
  const { data: userData, refetch } = useQuery('user', fetchCurrentUser)
  const platformIcons = { instagram, youtube, tiktok }
  const [platformId, setPlatformId] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [browserAlertOpen, setBrowserAlertOpen] = useState(false)

  useEffect(() => {
    if (platform === 'instagram') {
      setPlatformId('9bb8913b-ddd9-430b-a66a-d74d846e6c66')
    } else if (platform === 'youtube') {
      setPlatformId('14d9ddf5-51c6-415e-bde6-f8ed36ad7054')
    } else if (platform === 'tiktok') {
      setPlatformId('de55aeec-0dc8-4119-bf90-16b3d1f0c987')
    }
  }, [platform])

  const phylloSDKConnect = async workPlatformId => {
    // check if user is using a real browser to show alert (phyllo social connection only works on real browsers)
    const isRealBrowser = window.navigator?.userAgent?.includes('Mozilla')
    if (!isRealBrowser) {
      setBrowserAlertOpen(true)
      return
    }

    const clientDisplayName = 'Creator.co'
    const environment = 'production'
    if (!userToken) {
      // user is not logged in
    } else {
      try {
        let userId = userData.creatorProfile?.phylloUserId
        let token = userData.phylloToken
        const redirect = false
        const config = {
          clientDisplayName,
          environment,
          userId,
          token,
          redirect,
          workPlatformId,
        }
        // eslint-disable-next-line no-undef
        const phyllo = PhylloConnect.initialize(config)

        // HANDLING ACCOUNT CONNECTED
        // NOTE: all callbacks required and each parameter must be present, even if not used
        // eslint-disable-next-line no-unused-vars
        phyllo.on('accountConnected', async (accountId, _workplatformId, _userId) => {
          setLoading(true)
          // Google Analytics Tracking
          window.dataLayer.push({
            event: `connect_${platform}_cr`,
          })
          await updateSocialProfile(accountId)
          // show loading state until user data is refetched
          refetch().then(() => setLoading(false))
        })

        // HANDLING ACCOUNT DISCONNECTED
        // phyllo callbacks require each parameter to be present, even if not used
        // eslint-disable-next-line no-unused-vars
        phyllo.on('accountDisconnected', async (_accountId, _workplatformId, _userId) => {
          setLoading(true)
          await deleteSocialProfile(platform)
          // show loading state until user data is refetched
          refetch().then(() => setLoading(false))
        })

        // HANDLING SDK TOKEN EXPIRED (token more than 7 days old)
        // eslint-disable-next-line no-unused-vars
        phyllo.on('tokenExpired', async _userId => {})

        // HANDLING EXIT FROM SDK
        // eslint-disable-next-line no-unused-vars
        phyllo.on('exit', async (_reason, _userId) => {})

        // OPENING SDK
        phyllo.open()
      } catch (err) {
        setPhylloError(true)
      }
    }
  }

  return (
    <Wrapper className={reconnect && 'reconnect'}>
      {reconnect ? (
        <Button
          type='link'
          icon={<SyncOutlined />}
          loading={loading}
          className={`reconnect-btn ${connectionStatus !== 'CONNECTED' && 'not-connected'}`}
          onClick={() => phylloSDKConnect(platformId)}>
          {connectionStatus === 'CONNECTED' ? 'Manage' : 'Reconnect'}
        </Button>
      ) : (
        <Button type='secondary' onClick={() => phylloSDKConnect(platformId)} loading={loading}>
          Connect{' '}
          {platform === 'instagram'
            ? 'Instagram'
            : platform === 'youtube'
              ? 'YouTube'
              : platform === 'tiktok' && 'TikTok'}
          <img src={platformIcons[platform]} alt='' />
        </Button>
      )}

      <Modal
        title='In-App Browser Detected'
        open={browserAlertOpen}
        okText='Switch to Browser'
        onOk={() => {
          // https://stackoverflow.com/questions/75781840/force-link-to-switch-from-in-app-browser-to-real-browser
          // https://stackoverflow.com/questions/72348092/is-it-possible-to-open-a-url-in-a-client-default-mobile-browser-while-the-click
          const currentUrl = window.location.href
          setBrowserAlertOpen(false)
          window.open(currentUrl, '_system') // open in system browser
        }}>
        <p>
          It looks like you&apos;re using an in-app browser. Please use a real browser such as
          Google Chrome or Safari to connect your social accounts.
        </p>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  &.reconnect {
    width: auto;
  }
  .reconnect-btn {
    background: transparent;
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    height: fit-content !important;
    padding: 0 !important;
    margin: 0 !important;
    color: ${props => props.theme.crcoTechBlue};
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &.not-connected {
      color: #ffa500;
    }
    &:hover {
      color: ${props => props.theme.crcoMidnight};
    }
  }
  .ant-btn {
    height: 40px;
    margin: 5px auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      height: 25px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    max-width: 400px;
  }
`
