import { LockOutlined } from '@ant-design/icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { API_URL } from '../../../constants'

const PayoutSettings = () => {
  const [widgetUrl, setWidgetUrl] = useState('')

  useEffect(() => {
    axios.get(`${API_URL}/me/trolley-widget-url`).then(res => {
      setWidgetUrl(res.data.url)
    })
  }, [])

  return (
    <Wrapper id='payouts' className='settings-section'>
      <h2>Payout Settings</h2>
      <p className='security-info'>
        <LockOutlined /> Securely powered by Trolley.
      </p>
      <p className='info-text'>
        Your banking details are not stored on Creator.co&apos;s platform. For more information, see
        our{' '}
        <a target='_blank' rel='noopener noreferrer' href='https://creator.co/creator-faq'>
          FAQ
        </a>{' '}
        and{' '}
        <a target='_blank' rel='noopener noreferrer' href='https://trolley.com/security-policies/'>
          Trolley&apos;s Security Policies
        </a>
        .
      </p>
      <iframe className='trolley-connect' width='100%' src={widgetUrl} title='trolley'></iframe>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .security-icon {
    width: 25px;
  }
  .trolley-connect {
    background: #eee;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    flex: 1;
  }
`

export default PayoutSettings
