import { Button } from 'antd'
import axios from 'axios'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import LoadingHeart from '../../../general/LoadingHeart'
import { NotFound } from '../../NotFound'

const OptInConfirmation = () => {
  const { token } = useContext(UserContext)
  const { id } = useParams()

  const { data: optIn, status } = useQuery(['opt-in', id], async () => {
    try {
      const { data } = await axios.get(`${API_URL}/me/opt-in/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      return data
    } catch (err) {
      return null
    }
  })

  return (
    <Wrapper>
      {status === 'success' &&
        (optIn ? (
          <>
            <h1>Thanks for opting in!</h1>
            <p className='confirmation-text'>
              <b>{optIn.campaign.brand.name}</b> will review your profile shortly.
            </p>
            <div className='campaign'>
              <img src={optIn.campaign.primaryImageSrc} alt={optIn.campaign.title} />
              <p className='title'>{optIn.campaign.title}</p>
              <Link to={`/my-campaigns/${id}`}>
                <Button type='primary'>View Details</Button>
              </Link>
            </div>
          </>
        ) : (
          <NotFound
            message={'Opt-in not found.'}
            buttonText={'My Campaigns'}
            to={'/my-campaigns'}
          />
        ))}
      {status === 'loading' && (
        <div className='loading'>
          <LoadingHeart />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  h1 {
    font-family: 'Poppins-Bold', sans-serif;
    margin: 0;
  }
  .confirmation-text {
    max-width: 350px;
    margin: 10px 0;
    color: #777e90;
  }
  .campaign {
    background: #fff;
    border: 1px solid #e0e0f7;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
    max-width: 350px;
    img {
      border-radius: 10px;
    }
    .title {
      font-family: 'Poppins-Medium', sans-serif;
      font-size: 1rem;
    }
  }

  .not-found {
    background: #fff;
    display: grid;
    place-content: center;
    text-align: center;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: ${props => props.theme.crcoBoxShadow};
    .cta {
      margin: 20px auto 0 auto;
    }
  }
  .loading {
    width: 100%;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
  }
`

export default OptInConfirmation
