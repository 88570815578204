import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Button, Input, Modal, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'

import affiliate from '../../../../assets/images/icons/campaigns/affiliate.svg'
import bespoke from '../../../../assets/images/icons/campaigns/bespoke.svg'
import giveaway from '../../../../assets/images/icons/campaigns/giveaway.svg'
import shoutout from '../../../../assets/images/icons/campaigns/shoutout.svg'
import ugc from '../../../../assets/images/icons/campaigns/ugc.svg'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { Wrapper, ThumbnailWrapper } from '../opt-ins/OptInsListItem'
const socialIcons = { instagram, youtube, tiktok }

export const InvitesListItem = ({ invite }) => {
  const history = useHistory()
  const queryClient = new useQueryClient()
  const { brand, campaign } = invite

  const [declineModalVisible, setDeclineModalVisible] = useState(false)
  const [declineReason, setDeclineReason] = useState('')
  const [declineLoading, setDeclineLoading] = useState(false)

  const inviteStatus = invite.optInId ? 'activated' : invite.declineDate ? 'cancelled' : 'pending'

  const getOptInStatus = status => {
    switch (status) {
      case 'pending':
        return 'Pending'
      case 'activated':
        return 'Activated'
      case 'completed':
        return 'Completed'
      case 'cancelled':
        return invite.optIn?.cancelledData?.reason === 'expired' ? 'Expired' : 'Declined'
      default:
        return null
    }
  }

  const optInStatus = getOptInStatus(invite.optIn?.status)

  const Thumbnail = () => {
    const thumbnailUrl = !campaign.shoptypeProduct
      ? campaign.images?.filter(image => image.type === 'primary')[0]?.sizes[1]?.url
      : campaign.shoptypeProduct.primaryImageSrc?.imageSrc

    return (
      <ThumbnailWrapper status={inviteStatus} className='thumbnail-portrait'>
        <div className='thumbnail' style={{ backgroundImage: `url(${thumbnailUrl})` }} />
        <span style={{ marginTop: '2px' }}>
          {inviteStatus === 'pending' && 'Pending'}
          {inviteStatus === 'activated' && 'Accepted'}
          {inviteStatus === 'cancelled' && 'Declined'}
        </span>
      </ThumbnailWrapper>
    )
  }

  const handleDecline = async () => {
    setDeclineLoading(true)

    await axios
      .delete(`${API_URL}/me/campaigns/invites/${invite.id}`, { data: { declineReason } })
      .then(() => {
        message.success('Invite declined.')
        setDeclineModalVisible(false)
        queryClient.invalidateQueries('invites')
        queryClient.invalidateQueries('notifications')
      })
      .catch(() => {
        message.error('Sorry, something went wrong.')
      })
      .finally(() => {
        setDeclineLoading(false)
      })
  }

  return (
    <>
      <Wrapper
        onClick={() => {
          history.push(`/campaign/${invite.campaign.slug}`)
        }}>
        <div className='main'>
          <Thumbnail />
          <div className='info'>
            <div className='brand'>{brand.name}</div>
            <div className='title'>{campaign.title}</div>
            <div className='strategy'>
              <img
                className='icon'
                src={
                  campaign.strategy === 'ugc'
                    ? ugc
                    : campaign.strategy === 'shoutout'
                      ? shoutout
                      : campaign.strategy === 'giveaway'
                        ? giveaway
                        : campaign.strategy === 'affiliate'
                          ? affiliate
                          : bespoke
                }
                alt=''
              />
              {campaign.socialChannels?.map((channel, i) => (
                <img src={socialIcons[channel]} alt={channel} key={i} />
              ))}
              {campaign.contentFormats?.length && campaign.contentFormats[0].length ? (
                campaign.contentFormats.map(
                  format =>
                    !!format.length && (
                      <span key={format} className='format'>
                        {format === 'Video Post' ? 'Video' : format}
                        {campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()] &&
                          ` (${campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()]})`}
                      </span>
                    )
                )
              ) : (
                <span className='format'>Custom</span>
              )}
            </div>
            <div className='payout'>
              {campaign.maxPaidAmount ? (
                <>
                  <span className='label'>Payment: </span>
                  <span className='amount'>
                    $
                    {campaign.minPaidAmount
                      ? `${campaign.minPaidAmount}-${campaign.maxPaidAmount}`
                      : campaign.maxPaidAmount}{' '}
                    USD
                  </span>
                </>
              ) : (
                'No payment.'
              )}
            </div>
          </div>
        </div>

        <div className='status-wrapper'>
          <div className='status-container'>
            {inviteStatus === 'pending' ? (
              <>
                <span className='label'>
                  <ExclamationCircleOutlined /> Invited {moment(invite.createdAt).fromNow()}
                </span>
                <span className='actions'>
                  <Button
                    type='link'
                    onClick={e => {
                      e.stopPropagation()
                      // push to campaign page and begin opt-in process
                      history.push(`/campaign/${invite.campaign.slug}?accepting-invite=true`)
                    }}>
                    Accept
                  </Button>
                  <Button
                    type='link'
                    danger
                    onClick={e => {
                      e.stopPropagation()
                      setDeclineModalVisible(true)
                    }}>
                    Decline
                  </Button>
                </span>
              </>
            ) : inviteStatus === 'activated' ? (
              <>
                <span className='label'>
                  <CheckCircleOutlined /> Accepted Invite
                </span>
                <span className='date'>
                  {moment(invite.optIn?.created || 'Unknown Date').format('ll')}
                </span>
              </>
            ) : (
              inviteStatus === 'cancelled' && (
                <>
                  <span className='label'>
                    <CloseCircleOutlined /> Declined Invite
                  </span>
                  <span className='date'>
                    {moment(invite.declineDate || 'Unknown Date').format('ll')}
                  </span>
                </>
              )
            )}
          </div>
          {optInStatus && (
            <div className='status-container'>
              <span className='label'>Opt-In Status</span>
              <span className='status'>{optInStatus}</span>
            </div>
          )}
        </div>
      </Wrapper>

      <Modal
        title={`Declining invite from ${brand.name}`}
        open={declineModalVisible}
        onCancel={() => {
          setDeclineModalVisible(false)
        }}
        onOk={handleDecline}
        okText='Decline'
        okButtonProps={{ danger: true, disabled: !declineReason }}
        confirmLoading={declineLoading}
        destroyOnClose>
        <p>
          Please provide a reason for declining to collaborate with <b>{brand.name}</b> on their
          &quot;
          {campaign.title}&quot; campaign.
        </p>
        <Input.TextArea
          rows={4}
          maxLength={500}
          showCount
          count={declineReason.length}
          onChange={e => setDeclineReason(e.target.value)}
          placeholder='I am unable to participate in this campaign because...'
        />
      </Modal>
    </>
  )
}
