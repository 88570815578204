import { Select } from 'antd'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import AffiliateAwin from './AffiliateAwin'
import AffiliateCreatorCo from './AffiliateCreatorCo'
import AffiliateImpact from './AffiliateImpact'
import AffiliateRakuten from './AffiliateRakuten'
import AffiliateShareASale from './AffiliateShareASale'

const Affiliate = () => {
  const { tab } = useParams()
  const history = useHistory()

  const [key, setKey] = useState(tab || 'creator')

  return (
    <Wrapper>
      <Select
        className='program-select'
        value={key}
        onChange={e => {
          setKey(e)
          history.push(`/affiliate/${e}`)
        }}>
        <Select.Option value='creator'>Creator.co</Select.Option>
        <Select.Option value='awin'>Awin</Select.Option>
        <Select.Option value='rakuten'>Rakuten</Select.Option>
        <Select.Option value='shareasale'>ShareASale</Select.Option>
        <Select.Option value='impact'>Impact Radius</Select.Option>
      </Select>

      {key === 'creator' && <AffiliateCreatorCo />}
      {key === 'awin' && <AffiliateAwin />}
      {key === 'rakuten' && <AffiliateRakuten />}
      {key === 'shareasale' && <AffiliateShareASale />}
      {key === 'impact' && <AffiliateImpact />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 20px;

  .program-select {
    margin: 20px 0;
    width: 200px;
  }

  .dashboard-header {
    background-color: #fff;
    padding: 8px 12px;
    width: 100%;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 10px -10px;
    position: sticky;
    top: 50px;
    z-index: 1;
  }
`

export default Affiliate
