import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Alert, Radio, Space } from 'antd'
import axios from 'axios'
import { useContext, useState, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import styled from 'styled-components/macro'

import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import profileIllustration from '../../../../assets/images/profile-illustration.svg'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { findProfile } from '../../../../utils'
import { SocialConnect } from '../social-accounts/SocialConnect'

export const CreatorProfileSetup = () => {
  const history = useHistory()
  const { fetchCurrentUser, phylloError } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)
  const [step, setStep] = useState({
    current: 1,
    prev: 1,
  })

  useEffect(() => {
    // push back to /my-profile if user isn't subscribed or has already completed setup flow (has handle)
    if (!userData?.hasSubscription) {
      history.push('/my-profile')
    } else if (userData?.creatorProfile?.profileSlug) {
      // show confirmation screen (step 3)
      setStep({ current: 3, prev: 2 })
    }
  }, [userData, history])

  const fadeIn = useTransition(step.current, null, {
    from: step.current < step.prev ? { opacity: 0, right: '50%' } : { opacity: 0, right: '-50%' },
    enter: { opacity: 1, right: '0' },
    leave: step.current < step.prev ? { opacity: 0, right: '-50%' } : { opacity: 0, right: '50%' },
  })

  return (
    <Wrapper>
      {fadeIn.map(({ item, key, props }) => (
        <div key={key}>
          {item === 1 && (
            <ConnectSocial
              step={step}
              setStep={setStep}
              props={props}
              userData={userData}
              phylloError={phylloError}
            />
          )}
          {item === 2 && (
            <ClaimHandle step={step} setStep={setStep} props={props} userData={userData} />
          )}
          {item === 3 && (
            <Confirmation step={step} setStep={setStep} props={props} userData={userData} />
          )}
        </div>
      ))}
    </Wrapper>
  )
}

const ConnectSocial = ({ setStep, props, userData }) => {
  // STEP 1 - connect social accounts (show anything already connected and don't allow to continue without connecting at least 1 account)
  const history = useHistory()

  const platforms = ['instagram', 'tiktok', 'youtube']
  const platformIcons = { instagram, youtube, tiktok }

  const Connected = ({ platform }) => {
    const socialProfile = findProfile(userData?.socialProfiles, platform)
    return (
      <div className='connected-platform'>
        <div className='channel-info'>
          <CheckCircleOutlined /> <p className='handle'>{socialProfile.username}</p>
        </div>
        <img className='icon' src={platformIcons[platform]} alt={platform} />
      </div>
    )
  }

  const connected = platforms
    .filter(platform => findProfile(userData?.socialProfiles, platform))
    .map(platform => <Connected platform={platform} key={platform} />)

  const unconnected = platforms
    .filter(platform => !findProfile(userData?.socialProfiles, platform))
    .map(platform => <SocialConnect platform={platform} key={platform} />)

  return (
    <animated.div className='fade-in' style={{ ...props }}>
      <div className='step-container'>
        <div className='step-header'>
          <p>Creator Profile Setup</p>
          <button
            className='close-btn'
            onClick={() => {
              history.push('/my-dashboard')
            }}
            style={{ marginLeft: 'auto' }}>
            <CloseOutlined />
          </button>
        </div>
        <p>Thanks for subscribing! Now let&apos;s set up your new profile.</p>
        {connected.length ? (
          <Alert
            message={`You've connected ${connected.length} account${
              connected.length === 1 ? '' : 's'
            }.`}
            type='success'
            showIcon
          />
        ) : (
          <p className='instructions'>Connect your social accounts.</p>
        )}
        <div>
          <div className='social-connects'>{connected}</div>
          <div className='social-connects'>{unconnected}</div>
        </div>
        <div className='buttons'>
          <Button type='secondary' onClick={() => history.push('/my-dashboard')}>
            Cancel
          </Button>
          <Button
            disabled={!connected.length}
            type='primary'
            onClick={() => {
              setStep({ current: 2, prev: 1 })
            }}>
            Continue
          </Button>
        </div>
      </div>
    </animated.div>
  )
}

const ClaimHandle = ({ setStep, props, userData }) => {
  // STEP 2 - claim profile handle (profileSlug), show error if unavailable, push to confirmation screen if successful
  const history = useHistory()
  const queryClient = new useQueryClient()
  const [loading, setLoading] = useState(false)

  const [suggestedSlugs, setSuggestedSlugs] = useState([])
  const [slug, setSlug] = useState('')
  const [status, setStatus] = useState(undefined)

  useEffect(() => {
    let handles = []
    const platforms = ['instagram', 'tiktok', 'youtube']
    platforms.forEach(platform => {
      const socialProfile = findProfile(userData?.socialProfiles, platform)
      if (socialProfile?.username) handles.push(socialProfile.username)
    })

    // cleaning up handles (removing @, periods, underscores and spaces, lowercase)
    handles = handles.map(handle => {
      return handle
        .replace(/@/g, '')
        .replace(/\./g, '')
        .replace(/_/g, '')
        .replace(/ /g, '')
        .toLowerCase()
    })

    // removing duplicates
    handles = [...new Set(handles)]

    setSuggestedSlugs(handles)
  }, [userData])

  const claimProfileHandle = async slug => {
    setLoading(true)
    try {
      await axios.post(`${API_URL}/creator-profile/${slug}`).then(() => {
        queryClient.invalidateQueries('user')
        setStep({ current: 3, prev: 2 })
      })
    } catch (err) {
      setStatus(err.response.data.error)
      setLoading(false)
    }
  }

  return (
    <animated.div className='fade-in' style={{ ...props }}>
      <div className='step-container'>
        <div className='step-header'>
          <p>Creator Profile Setup</p>
          <button
            className='close-btn'
            onClick={() => {
              history.push('/my-dashboard')
            }}
            style={{ marginLeft: 'auto' }}>
            <CloseOutlined />
          </button>
        </div>
        <p className='instructions'>Choose the handle* you would like for your custom URL.</p>
        <div>
          <div className='browser-bar'>
            <span>https://</span>
            <span className={`handle ${slug && 'selected'}`}>{slug || 'yourhandle'}</span>
            <span>.creator.co</span>
          </div>
          <Radio.Group
            onChange={e => {
              setStatus(undefined)
              setSlug(e.target.value)
            }}>
            <Space direction='vertical'>
              {suggestedSlugs.map(slug => (
                <Radio key={slug} value={slug}>
                  {slug}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <p className='info-text'>
            * Options generated from your connected social channels. You can always change this
            later.
          </p>
        </div>
        {status && <Alert message={status} type='error' showIcon />}
        <div className='buttons'>
          <Button type='secondary' onClick={() => setStep({ current: 1, prev: 2 })}>
            Back
          </Button>
          <Button
            type='primary'
            disabled={!slug}
            loading={loading}
            onClick={() => {
              claimProfileHandle(slug)
            }}>
            Claim Profile
          </Button>
        </div>
      </div>
    </animated.div>
  )
}

const Confirmation = ({ props, userData }) => {
  // CONFIRMATION SCREEN
  const history = useHistory()
  const profileSlug = userData.creatorProfile.profileSlug

  return (
    <animated.div className='fade-in' style={{ ...props }}>
      <div className='step-container'>
        <div className='step-header'>
          <p>Creator Profile Setup</p>
          <button
            className='close-btn'
            onClick={() => {
              history.push('/my-dashboard')
            }}>
            <CloseOutlined />
          </button>
        </div>
        <div className='step'>
          <Alert message='Your profile has been created.' type='success' showIcon />
          <div className='browser-bar'>
            <span>https://</span>
            <span className='handle selected'>{profileSlug}</span>
            <span>.creator.co</span>
          </div>
          <img className='illustration' src={profileIllustration} alt='' />
          <Button
            type='primary'
            onClick={() => {
              history.push('/my-profile')
            }}>
            Edit Profile
          </Button>
        </div>
      </div>
    </animated.div>
  )
}

const Wrapper = styled.div`
  background: #f4f4f8;
  overflow-y: overlay;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  height: 100%;
  .fade-in {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 100%;
    width: 100%;
    display: flex;
  }
  .step-container {
    background: #fff;
    width: 100%;
    max-width: 90vw;
    box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
    border-radius: 40px;
    padding: 40px 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1rem;
    .step-header {
      font-family: 'Poppins-Bold', sans-serif;
      color: #23262f;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        margin: 0;
        font-size: 1rem;
      }
      button {
        background: transparent;
        border: none;
        border-radius: 50%;
        padding: 5px;
        display: grid;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
    }
    h2 {
      text-align: center;
    }
    .step {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  // SOCIAL CONNECTS
  .social-connects {
    display: flex;
    flex-direction: column;
    align-items: center;
    .connected-platform {
      background: #ecf6ff;
      border: 1px solid ${props => props.theme.crcoTechBlue};
      border-radius: 20px;
      color: ${props => props.theme.crcoTechBlue};
      padding: 4px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin: 5px 0;
      width: 100%;
      min-height: 40px;
      .channel-info {
        display: flex;
        align-items: center;
        gap: 5px;
        overflow: hidden;
        p {
          margin: 0;
        }
        .handle {
          font-size: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .status {
          font-size: 0.8rem;
        }
      }
      .icon {
        height: 25px;
      }
    }
  }
  // PROFILE SLUG
  .browser-bar {
    background: ${props => props.theme.crcoOffWhite};
    color: #999;
    width: 100%;
    border: 1px solid #e6e6e6;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 1rem;
    max-width: 100%;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    .handle {
      transition: 0.2s ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;
      &.selected {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .ant-radio-group {
    margin: 20px 10px;
  }
  .illustration {
    margin: 20px;
  }
  @media only screen and (min-width: 500px) {
    .step-container {
      width: 400px;
      max-height: 600px;
      padding: 40px;
      .step-header {
        margin-bottom: 30px;
      }
    }
  }
`
