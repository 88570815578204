import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Switch, Button, Drawer, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useState, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components/macro'

import { EngagementBreakdown } from './EngagementBreakdown'
import { SocialConnect } from './SocialConnect'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { ImageLoader } from '../../../general/ImageLoader'

export const ProfileCard = ({ platform, toggle }) => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)
  const queryClient = new useQueryClient()
  const [socialProfile, setSocialProfile] = useState()
  const [connectionStatus, setConnectionStatus] = useState(undefined)
  const [channelVisibility, setChannelVisibility] = useState(undefined)
  const [engagement, setEngagement] = useState(undefined)
  const [engagementDrawerOpen, setEngagementDrawerOpen] = useState(false)
  const platformIcons = { instagram, youtube, tiktok }

  useEffect(() => {
    setSocialProfile(userData.socialProfiles.find(profile => profile.platform === platform))
  }, [userData, setSocialProfile, platform])

  // getting phyllo account connection status - possible values are: CONNECTED, NOT_CONNECTED, SESSION_EXPIRED
  // if the account is not connected or there is an error, the user will be prompted to reconnect
  const getConnectionStatus = async () => {
    try {
      if (!socialProfile?.phylloData?.account?.id) {
        setConnectionStatus('NOT_CONNECTED')
      } else {
        const { data } = await axios.get(
          `${API_URL}/phyllo-account/${socialProfile?.phylloData?.account?.id}`
        )
        setConnectionStatus(data?.status || 'ERROR')
      }
    } catch (err) {
      setConnectionStatus('ERROR')
    }
  }

  useEffect(() => {
    if (socialProfile) {
      getConnectionStatus()
    }
  }, [socialProfile])

  useEffect(() => {
    if (connectionStatus) {
      // set engagement rate
      setEngagement(
        socialProfile?.engagement
          ? socialProfile.engagement.toFixed(2) + '%'
          : connectionStatus === 'CONNECTED'
            ? socialProfile?.updated
              ? '0%'
              : 'Calculating...'
            : 'N/A'
      )
    }
  }, [connectionStatus, socialProfile])

  // FOR CREATOR PROFILE
  useEffect(() => {
    if (socialProfile?.visibility) {
      setChannelVisibility(socialProfile.visibility === 'public')
    }
  }, [socialProfile])

  const handleToggle = async checked => {
    setChannelVisibility(checked)
    await axios
      .post(`${API_URL}/creator-profile-channels`, {
        platform,
        visibility: checked ? 'public' : 'private',
      })
      .then(() => {
        queryClient.invalidateQueries('user')
      })
      .catch(() => {
        message.error('Oops, something went wrong.')
      })
  }

  return socialProfile ? (
    <Wrapper
      className={`${platform} ${
        connectionStatus && (connectionStatus === 'CONNECTED' ? 'connected' : 'not-connected')
      }`}>
      <div className='profile-info'>
        <div className='user'>
          <div className='avatar'>
            <ImageLoader src={socialProfile.profilePicUrl} alt='' />
          </div>
          <div className='info'>
            <a
              className={`handle ${platform}`}
              href={socialProfile.profileUrl}
              target='_blank'
              rel='noopener noreferrer'>
              {socialProfile.username}
            </a>
            <p className='connection-status'>
              {!connectionStatus ? (
                <LoadingOutlined spin />
              ) : (
                <>
                  {connectionStatus === 'CONNECTED' ? (
                    <CheckCircleOutlined />
                  ) : (
                    <ExclamationCircleOutlined />
                  )}
                  {connectionStatus === 'CONNECTED' && 'Connected'}
                  {connectionStatus === 'NOT_CONNECTED' && 'Not connected'}
                  {connectionStatus === 'SESSION_EXPIRED' && 'Connection expired'}
                  {connectionStatus === 'ERROR' && 'Error - Please reconnect'}
                </>
              )}
            </p>
          </div>
        </div>
        <img className='logo' src={platformIcons[platform]} alt='' />
      </div>
      <div className='stats'>
        <div className='row'>
          <span className='label'>{platform === 'youtube' ? 'Subscribers' : 'Followers'}</span>
          <span className='num'>
            {socialProfile.followerCount > 999
              ? numeral(socialProfile.followerCount).format('0.0a')
              : socialProfile.followerCount || 0}
          </span>
        </div>
        <div className='row'>
          <span className='label'>Engagement Rate</span>
          <span className='num'>
            {engagement}
            <Button type='link' onClick={() => setEngagementDrawerOpen(true)}>
              <QuestionCircleOutlined />
            </Button>
          </span>
        </div>
        <div className='row date'>
          <span className='label'>Last Updated</span>
          <span className='num'>
            {socialProfile.updated ? moment(socialProfile.updated).format('ll') : 'Never'}
          </span>
        </div>
        {engagement === 'Calculating...' && (
          <div className='row'>
            <span className='info'>
              <InfoCircleOutlined /> Please wait up to 48 hours for your engagement rate to be
              calculated.
            </span>
          </div>
        )}
        {(engagement === '0%' || engagement === 'N/A') && (
          <div className='row'>
            <span className='warning'>
              <InfoCircleOutlined /> It looks like you have no recent activity on this account,
              resulting in zero engagement.
            </span>
          </div>
        )}
      </div>
      {connectionStatus && (
        <div className='actions'>
          <SocialConnect connectionStatus={connectionStatus} reconnect={true} platform={platform} />
          {toggle && (
            <Switch
              checkedChildren='Public'
              unCheckedChildren='Private'
              checked={channelVisibility}
              onClick={checked => handleToggle(checked)}
            />
          )}
        </div>
      )}

      <Drawer
        title='Engagement Breakdown'
        open={engagementDrawerOpen}
        onClose={() => setEngagementDrawerOpen(false)}
        destroyOnClose={true}>
        <EngagementBreakdown
          socialProfile={socialProfile}
          engagementBreakdown={socialProfile.metaData?.engagementBreakdown}
          userId={userData.id}
        />
      </Drawer>
    </Wrapper>
  ) : (
    <></>
  )
}

const Wrapper = styled.div`
  background: ${props => props.theme.crcoOffWhite};
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
  width: 100%;
  .basic-profile-card {
    background: #ecf6ff;
    border: 1px solid ${props => props.theme.crcoTechBlue};
    color: ${props => props.theme.crcoTechBlue};
    border-radius: 5px;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    width: 100%;
    max-width: 300px;
    .channel-info {
      p {
        margin: 0;
      }
      .handle {
        font-size: 1rem;
      }
      .status {
        font-size: 0.8rem;
      }
    }
    .icon {
      height: 25px;
    }
  }
  &.connected {
    background: #ecf6ff;
    border: 1px solid #b6dcff;
    .handle {
      color: ${props => props.theme.crcoTechBlue};
    }
  }
  &.not-connected {
    background: #fff3dc;
    border: 1px solid #ffdb9a;
    .handle {
      color: #ffa500;
    }
  }
  .actions {
    background: #fff;
    color: #23262f;
    border-top: 1px solid #e6e6e6;
    padding: 5px 10px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .user {
      display: flex;
      align-items: center;
      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
      .info {
        margin: 0 10px;
        p {
          margin: 0;
        }
      }
      .handle {
        margin: 0;
        word-break: break-word;
      }
      .connection-status {
        font-size: 0.8rem;
        opacity: 0.5;
        .anticon {
          margin-right: 5px;
        }
      }
    }
    .logo {
      height: 25px;
      margin-left: 10px;
    }
  }
  .stats {
    font-size: 0.8rem;
    padding: 5px 10px;
    .row {
      display: flex;
      justify-content: space-between;
      padding: 3px 0;
      border-top: 1px dotted rgba(0, 0, 0, 0.1);
      &:first-child {
        border: none;
      }
      &.date {
        opacity: 0.5;
      }
      .num {
        display: flex;
        align-items: center;
        gap: 6px;
      }
      button {
        padding: 0;
        margin: 0;
        border: 0;
        height: fit-content;
      }
    }
    .info {
      color: ${props => props.theme.crcoTechBlue};
    }
    .warning {
      color: ${props => props.theme.crcoCoral};
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    max-width: 400px;
  }
`
