import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Route } from 'react-router-dom'
import styled from 'styled-components/macro'

import Affiliate from './affiliate/Affiliate'
import { CampaignDashboard } from './CampaignDashboard'
import { CreatorProfileSettings } from './creator-profile/CreatorProfileSettings'
import { CreatorProfileSetup } from './creator-profile/CreatorProfileSetup'
import DashboardHome from './DashboardHome'
import DashboardSidebar from './DashboardSidebar'
import { SingleOptIn } from './opt-ins/SingleOptIn'
import ProfileSettings from './ProfileSettings'
import { Wishlist } from './Wishlist'
import { OptInContextProvider } from '../../../contexts/OptInContext'
import { UserContext } from '../../../contexts/UserContext'
import LoadingHeart from '../../general/LoadingHeart'

const Dashboard = () => {
  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData, status } = useQuery('user', fetchCurrentUser)

  return (
    <Wrapper>
      {status === 'success' && userData ? (
        <>
          <DashboardSidebar userData={userData} />
          <main>
            <OptInContextProvider>
              <Route exact path='/my-dashboard'>
                <DashboardHome />
              </Route>
              <Route exact path='/my-campaigns'>
                <CampaignDashboard />
              </Route>
              <Route path='/my-campaigns/:id'>
                <SingleOptIn />
              </Route>
            </OptInContextProvider>
            <Route path='/my-wishlist'>
              <Wishlist />
            </Route>
            <Route path={['/affiliate/:tab', '/affiliate']}>
              <Affiliate />
            </Route>
            <Route path='/my-settings'>
              <ProfileSettings />
            </Route>
            <Route path='/my-profile'>
              <CreatorProfileSettings />
            </Route>
            <Route path='/creator-profile-setup'>
              <CreatorProfileSetup />
            </Route>
          </main>
        </>
      ) : (
        <div className='loading'>
          <LoadingHeart />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex: 1;
  main {
    flex: 1;
    .heading {
      background: linear-gradient(
        to right,
        ${props => props.theme.crcoCottonCandyBlue},
        ${props => props.theme.crcoCottonCandyPink}
      );
      padding: 10px 20px;
      text-align: center;
      z-index: 1;
      h1 {
        font-size: 1.2rem;
        margin: 0;
      }
      @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
        padding: 20px 30px;
        h1 {
          font-size: 1.4rem;
        }
      }
      @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
        padding: 30px 40px;
        h1 {
          font-size: 1.6rem;
        }
      }
    }
  }
  .loading {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    margin: auto;
  }
  .beta {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto 20px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    p {
      margin: 20px 0 40px 0;
    }
    @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
      margin: auto;
    }
  }
`

export default Dashboard
