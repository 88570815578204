import axios from 'axios'
import LogRocket from 'logrocket'
import React, { createContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { API_URL } from '../constants'

export const UserContext = createContext(null)

export const UserContextProvider = props => {
  const [token, setToken] = useState(localStorage.getItem('crcoToken'))
  const [phylloError, setPhylloError] = useState(false)
  const queryClient = new useQueryClient()
  const [lgLoaded, setLgLoaded] = useState(!!window.logRocketInitialized)

  useEffect(() => {
    // Automatically set/unset default axios headers, localStorage token, query for current user
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('crcoToken', token)
    } else {
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('crcoToken')
    }
  }, [token])

  const loginHandler = async data => {
    try {
      const res = await axios.post(`${API_URL}/login`, data)
      setToken(res.data.token)
      return res.data
    } catch (err) {
      return err.response.data
    }
  }

  const signupHandler = async data => {
    try {
      const res = await axios.post(`${API_URL}/creator/register`, data)
      setToken(res.data.token)

      // Google Analytics Tracking
      window.dataLayer.push({
        event: 'creator_signup_complete_cr',
      })

      // trigger influencer_signup event on user.com to send automated welcome emails
      window.userengage('event.influencer_signup', {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      })

      return res.data
    } catch (err) {
      return { error: err.response.data?.err }
    }
  }

  const logoutHandler = () => {
    setToken('')
    queryClient.removeQueries('user')
  }

  const fetchCurrentUser = async () => {
    if (!token) return null

    // ref used for zendesk SSO
    const zendeskRedirectTo = localStorage.getItem('zendesk-ref')

    try {
      const { data } = await axios.get(`${API_URL}/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { zendeskRedirectTo },
      })
      if (data.zendeskEndpoint) {
        localStorage.removeItem('zendesk-ref')
        window.location.href = data.zendeskEndpoint
      }

      // Record creator user sessions on production with LogRocket
      if (!lgLoaded && data.role !== 'administrator' && process.env.NODE_ENV === 'production') {
        LogRocket.init('zbp1lp/creator-co', {
          // Sanitize the request body to avoid sending sensitive data to LogRocket (such as passwords)
          requestSanitizer: request => {
            if (request.url.toLowerCase().indexOf('/login') !== -1) request.body.password = null
            return request
          },
        })

        // Identify the user
        LogRocket.identify(data.id, {
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          // User traits (can be used to search sessions)
          type: data.role,
        })

        // Prevent LogRocket from initializing multiple times
        setLgLoaded(true)
      }

      return data
    } catch (err) {
      if (err?.response?.status === 401) {
        // unauthorized request, remove token
        setToken('')
      }
      return null
    }
  }

  const updateCurrentUser = async data => {
    try {
      const res = await axios.post(`${API_URL}/me`, data)
      queryClient.invalidateQueries('user')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const optInCreator = async (campaignId, variables, selectedProduct, channels) => {
    try {
      const res = await axios.post(`${API_URL}/opt-in-creator`, {
        campaignId,
        variables,
        selectedProduct,
        channels,
        inviteCode: localStorage.getItem('inviteCode'),
      })
      return res.data
    } catch (err) {
      return { error: err.response.data }
    }
  }

  // HANDLING USER DATA SPECIFIC TO CREATORS
  const updateCreatorProfile = async data => {
    try {
      const res = await axios.post(`${API_URL}/me/creator-settings`, data)
      queryClient.invalidateQueries('user')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  // HANDLING CREATOR PROFILE SETTINGS
  // used in CreatorProfileSettings.js - updates user/user.creatorProfile/creatorProfile.profileSettings depending on values passed in
  const updateProfileSettings = async data => {
    try {
      const res = await axios.post(`${API_URL}/creator-profile-settings`, data)
      queryClient.invalidateQueries('user')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  // HANDLING SOCIAL ACCOUNT CONNECTED
  const updateSocialProfile = async accountId => {
    try {
      const res = await axios.post(`${API_URL}/me/social-connect/${accountId}`)
      await queryClient.invalidateQueries('user')
      return res
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const deleteSocialProfile = async platform => {
    try {
      const res = await axios.delete(`${API_URL}/me/social-connect/${platform}`)
      await queryClient.invalidateQueries('user')
      return res
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const closeAccount = async (userId, data) => {
    try {
      const res = await axios.delete(`${API_URL}/creator/${userId}/account`, {
        data,
      })
      setToken('')
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('crcoToken')
      queryClient.invalidateQueries('user')
      return res
    } catch (err) {
      return { error: err.response?.data?.err || 'An unknown error occurred.' }
    }
  }

  const fetchInvites = async () => {
    const { data } = await axios.get(`${API_URL}/me/invites`)
    return data
  }

  const fetchNotifications = async () => {
    const { data } = await axios.get(`${API_URL}/me/notifications`)
    return data
  }

  const userContextValues = {
    token,
    phylloError,
    setPhylloError,
    loginHandler,
    logoutHandler,
    signupHandler,
    fetchCurrentUser,
    updateCurrentUser,
    updateCreatorProfile,
    updateProfileSettings,
    updateSocialProfile,
    deleteSocialProfile,
    optInCreator,
    closeAccount,
    fetchInvites,
    fetchNotifications,
  }

  return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>
}
