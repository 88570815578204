import { ExclamationCircleOutlined, HeartFilled, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Rate, message, Tooltip } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import affiliate from '../../../../assets/images/icons/campaigns/affiliate.svg'
import bespoke from '../../../../assets/images/icons/campaigns/bespoke.svg'
import giveaway from '../../../../assets/images/icons/campaigns/giveaway.svg'
import shoutout from '../../../../assets/images/icons/campaigns/shoutout.svg'
import ugc from '../../../../assets/images/icons/campaigns/ugc.svg'
import instagram from '../../../../assets/images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../assets/images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../assets/images/icons/social/youtube-color-square.svg'
import { API_URL } from '../../../../constants'
import { getAverageRating } from '../../../../utils'
const socialIcons = { instagram, youtube, tiktok }

const OptInsListItem = ({ optIn }) => {
  const history = useHistory()
  const [unreadMessages, setUnreadMessages] = useState(0)
  const [reinviteLoading, setReinviteLoading] = useState(false)
  const queryClient = new useQueryClient()

  useEffect(() => {
    // set unread message count where message sender is not current user and is unread
    setUnreadMessages(
      optIn.chat?.messages?.filter(message => message.sender !== 'creator' && !message.read).length
    )
  }, [optIn.chat?.messages])

  const acceptReinvite = () => {
    setReinviteLoading(true)
    // set opt-in to 'activated' and set 'reinvited' to null in backend
    axios
      .post(`${API_URL}/opt-in/${optIn.id}/accept-reinvite`)
      .then(() => {
        message.success('Invite accepted!')
        queryClient.invalidateQueries('opt-ins')
      })
      .finally(() => {
        setReinviteLoading(false)
      })
  }

  const Thumbnail = () => {
    const thumbnailUrl = !optIn.campaign.shoptypeProduct
      ? optIn.campaign.images?.filter(image => image.type === 'primary')[0]?.sizes[1]?.url
      : optIn.campaign.shoptypeProduct.primaryImageSrc?.imageSrc

    let statusText = ''
    if (optIn.reinvited) {
      statusText = 'Reinvited'
    } else {
      switch (optIn.status) {
        case 'pending':
          statusText = 'Pending'
          break
        case 'activated':
          statusText = 'Activated'
          break
        case 'completed':
          statusText = 'Completed'
          break
        case 'cancelled':
          statusText = optIn.cancelledData?.reason === 'expired' ? 'Expired' : 'Declined'
          break
        default:
          break
      }
    }

    return (
      <ThumbnailWrapper
        status={optIn.reinvited ? 'reinvited' : optIn.status}
        className='thumbnail-portrait'>
        <div className='thumbnail' style={{ backgroundImage: `url(${thumbnailUrl})` }} />
        <span style={{ marginTop: '2px' }}>{statusText}</span>
      </ThumbnailWrapper>
    )
  }

  return (
    <Wrapper onClick={() => history.push(`/my-campaigns/${optIn.id}`)}>
      <div className='main'>
        <Thumbnail />
        <div className='info'>
          <div className='brand'>{optIn.campaign.brand.name}</div>
          <div className='title'>{optIn.campaign.title}</div>
          <div className='strategy'>
            <img
              className='icon'
              src={
                optIn.campaign.strategy === 'ugc'
                  ? ugc
                  : optIn.campaign.strategy === 'shoutout'
                    ? shoutout
                    : optIn.campaign.strategy === 'giveaway'
                      ? giveaway
                      : optIn.campaign.strategy === 'affiliate'
                        ? affiliate
                        : bespoke
              }
              alt=''
            />
            {optIn.campaign.socialChannels?.map((channel, i) => (
              <img src={socialIcons[channel]} alt={channel} key={i} />
            ))}
            {optIn.campaign.contentFormats?.length && optIn.campaign.contentFormats[0].length ? (
              optIn.campaign.contentFormats.map(
                format =>
                  !!format.length && (
                    <span key={format} className='format'>
                      {format === 'Video Post' ? 'Video' : format}
                      {optIn.campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()] &&
                        ` (${
                          optIn.campaign.metaData?.contentFormatCounts?.[format?.toLowerCase()]
                        })`}
                    </span>
                  )
              )
            ) : (
              <span className='format'>Custom</span>
            )}
          </div>
          <div className='payout'>
            {optIn.campaign.maxPaidAmount ? (
              <>
                <span className='label'>Payment: </span>
                <span className='amount'>
                  $
                  {optIn.campaign.minPaidAmount
                    ? `${optIn.campaign.minPaidAmount}-${optIn.campaign.maxPaidAmount}`
                    : optIn.campaign.maxPaidAmount}{' '}
                  USD
                </span>
              </>
            ) : (
              'No payment.'
            )}
          </div>
        </div>
      </div>

      <div className='status-wrapper'>
        {optIn.reinvited && (
          <div className='status-container'>
            <span className='label'>
              <ExclamationCircleOutlined /> Reinvited {moment(optIn.reinvited).fromNow()}
            </span>
            <span className='actions'>
              {/* TODO: add functionality to decline? */}
              <Button
                type='link'
                loading={reinviteLoading}
                onClick={e => {
                  e.stopPropagation()
                  acceptReinvite()
                }}>
                Accept
              </Button>
            </span>
          </div>
        )}

        <div className='status-container'>
          {optIn.status === 'pending' ? (
            <>
              <span className='label'>Opted In</span>
              <span className='date'>{moment(optIn.created).format('ll')}</span>
            </>
          ) : optIn.status === 'activated' ? (
            <>
              <span className='label'>Activated</span>
              <span className='date'>{moment(optIn.activated).format('ll')}</span>
            </>
          ) : optIn.status === 'completed' ? (
            <>
              <span className='label'>Completed</span>
              <span className='date'>{moment(optIn.completed).format('ll')}</span>
            </>
          ) : (
            optIn.status === 'cancelled' && (
              <>
                <span className='label'>
                  {optIn.cancelledData?.reason === 'expired' ? 'Expired' : 'Declined'}
                </span>
                <span className='date'>{moment(optIn.cancelled).format('ll')}</span>{' '}
              </>
            )
          )}
        </div>

        {['activated', 'completed'].includes(optIn.status) && optIn.currentStep && (
          <div className='status-container'>
            <span className='label'>
              Step {optIn.currentStep.placement} of {optIn.campaign.steps.length}
            </span>
            {optIn.currentStep.actionee === 'creator' ? (
              <span className='step creator'>Action required</span>
            ) : (
              <span className='step brand'>Waiting on brand</span>
            )}
          </div>
        )}
        {optIn.rating && (
          <div className='status-container'>
            <span className='label'>Your Rating</span>
            <span>
              <Rate
                value={getAverageRating(optIn.rating)}
                character={<HeartFilled />}
                className='rate'
                disabled
                allowHalf
              />
              <Tooltip
                title={
                  <TooltipText>
                    <p>
                      <b>Average Rating: {getAverageRating(optIn.rating)}</b>
                    </p>
                    <p>Content: {optIn.rating.content || 0}</p>
                    <p>Creativity: {optIn.rating.creativity || 0}</p>
                    <p>Communication: {optIn.rating.communication || 0}</p>
                    <p>Overall: {optIn.rating.overall || 0}</p>
                  </TooltipText>
                }>
                {getAverageRating(optIn.rating)} <InfoCircleOutlined />
              </Tooltip>
            </span>
          </div>
        )}

        {!!unreadMessages && (
          <div className='status-container'>
            <span className='label'>New Messages</span>
            <span className={`messages ${!!unreadMessages && 'unread'}`}>
              <span className='notification-badge' />
              {unreadMessages}
            </span>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 15px 20px;
  transition: 0.2s ease-in-out;
  font-size: 0.8rem;
  cursor: pointer;
  &:not(:first-child) {
    border-top: 1px solid #e6e6e6;
  }
  // prevent hover effect on mobile
  @media (hover: hover) {
    // TODO: better hover effect styling
    &:hover {
      .title {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .main {
    display: flex;
    align-items: center;
    color: ${props => props.theme.crcoMidnight};
    margin-bottom: 10px;
  }
  .info {
    flex: 1;
    .brand {
      color: ${props => props.theme.crcoTechBlue};
    }
    .title {
      font-size: 1rem;
      transition: 0.2s ease-in-out;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .strategy {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      img {
        height: 20px;
      }
      .format {
        background: #ecf6ff;
        color: #027df0;
        display: grid;
        place-content: center;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 0.7rem;
      }
    }
    .payout {
      font-size: 0.8rem;
      margin-top: 5px;
      color: rgba(0, 0, 0, 0.3);
      .amount {
        color: #00c457;
        font-weight: bold;
      }
    }
  }
  .status-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    white-space: nowrap;
    gap: 5px;
    margin-top: 10px;
    .status-container {
      background: #f9f9f9;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px 10px;
    }
    .rate {
      color: ${props => props.theme.crcoCoral};
      margin-right: 5px;
    }
    .label {
      color: ${props => props.theme.crcoGrey};
    }
    .date {
      color: #333;
    }
    .step {
      color: #fff;
      border-radius: 10px;
      padding: 2px 10px 0 10px;
      font-size: 0.7rem;
      text-transform: uppercase;
      &.creator {
        background: ${props => props.theme.crcoCoral};
      }
      &.brand {
        background: #999;
      }
      &.reinvite {
        background: ${props => props.theme.crcoLavender};
      }
    }
    .messages {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      color: #999;
      .notification-badge {
        background: #ccc;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid white;
      }
      .inbox-icon {
        position: relative;
        font-size: 1.2rem;
        display: grid;
      }
      p {
        margin: 0;
      }
      &.unread {
        color: ${props => props.theme.crcoMidnight};
        .notification-badge {
          background-color: ${props => props.theme.crcoCoral};
        }
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .status-wrapper {
      margin: 0;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    display: flex;
    .main {
      flex: 2;
      margin: 0;
    }
    .status-wrapper {
      flex: 1;
    }
  }
`

export const ThumbnailWrapper = styled.div`
  width: 80px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  margin-right: 12px;
  font-size: 0.8rem;

  .thumbnail {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 4px;
    flex: 1;
  }

  border: 2px solid
    ${props => {
      switch (props.status) {
        case 'pending':
          return '#bbb'
        case 'activated':
          return '#027df0'
        case 'cancelled':
          return '#FC9C44'
        case 'reinvited':
          return '#b0a1d8'
        default:
          // completed
          return '#18D96E'
      }
    }};
  background-color: ${props => {
    switch (props.status) {
      case 'pending':
        return '#bbb'
      case 'activated':
        return '#027df0'
      case 'cancelled':
        return '#FC9C44'
      case 'reinvited':
        return '#b0a1d8'
      default:
        // completed
        return '#18D96E'
    }
  }};
`

export const TooltipText = styled.div`
  p {
    margin: 0;
  }
`

export default OptInsListItem
