import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from 'react-query'

import { App } from './App'
import { CampaignContextProvider } from './contexts/CampaignContext'
import { GlobalContextProvider } from './contexts/GlobalContext'
import { UserContextProvider } from './contexts/UserContext'
import reportWebVitals from './reportWebVitals'

import '../node_modules/slick-carousel/slick/slick.css'
import '../node_modules/slick-carousel/slick/slick-theme.css'

TagManager.initialize({
  gtmId: 'GTM-TF5SHQV',
})

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <CampaignContextProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </CampaignContextProvider>
      </GlobalContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
